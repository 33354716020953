<template>
  <div class="container">
    <canvas id="plane_canvas" width="600" height="200"></canvas>
    <img id="img" src="@/assets/paper_plane.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      start: [0, 130],
      point: [90, 20],
      point2: [140, 180],
      end: [300, 110],
      percent: 0,
      ctx: null,
      img: null,
    };
  },
  created() {
    // this.ctx = document.getElementById("plane_canvas").getContext("2d");
    // this.img = document.getElementById("img");
    // this.percent = 0;
  },
  mounted() {
    if (process.env.WEBPACK_TARGET !== "node") {
      this.ctx = document.getElementById("plane_canvas").getContext("2d");
      this.img = document.getElementById("img");
    }
    this.percent = 0;
    this.init();
  },
  methods: {
    init() {
      this.percent = 0;
      this.draw();
    },
    draw() {
      this.ctx.clearRect(0, 0, 1200, 500);
      this.ctx.lineWidth = 1;
      this.ctx.strokeStyle = "#347FF2";
      this.ctx.setLineDash([15]); // 虚线间隔
      this.drawCurvePath(
        this.start,
        this.point,
        this.point2,
        this.end,
        this.percent
      );
      this.percent += 5;
      if (this.percent <= 100) {
        requestAnimationFrame(this.draw);
      }
    },
    drawCurvePath(start, point, point2, end, percent) {
      this.ctx.beginPath();
      this.ctx.moveTo(start[0], start[1]);
      for (let i = 0; i <= percent / 100; i += 0.005) {
        var x = this.quadraticBezier(start[0], point[0], point2[0], end[0], i);
        var y = this.quadraticBezier(start[1], point[1], point2[1], end[1], i);
        this.ctx.lineTo(x, y);
      }
      this.ctx.stroke();
      this.drawImg(x, y);
    },
    //  三次贝塞尔曲线公式
    quadraticBezier(p0, p1, p2, p3, t) {
      let k = 1 - t;
      return (
        k * k * k * p0 +
        3 * k * k * t * p1 +
        3 * p2 * t * t * k +
        p3 * t * t * t
      ); //三次贝塞尔
    },
    drawImg(x, y) {
      this.ctx.drawImage(this.img, x, y - 27, 60, 40);
    },
  },
};
</script>

<style lang="scss" scoped>
#img {
  display: none;
  /* <!--img直接隐藏就行,后面会直接引用--> */
}
</style>
