<template>
  <div class="container">
    <!-- 业务范畴 -->
    <div class="business-scope wrap">
      <div class="com-title">
        <span>业务范畴</span>
        <span>/</span>
        <span>Business scope</span>
        <div class="anchor-point" id="business-scope"></div>
      </div>
      <div class="content">
        <div
          class="item animate__animated animate__slideInUp"
          v-for="(item, index) in businessScopeList"
          :key="index"
        >
          <img :src="item.icon" :alt="item.title" />
          <span>{{ item.title }}</span>
          <span>{{ item.desc }}</span>
        </div>
      </div>
    </div>
    <!-- 经典案例 -->
    <div class="classic-cases wrap">
      <div class="com-title">
        <span>经典案例</span>
        <span>/</span>
        <span>Classic cases</span>
        <div class="anchor-point" id="classic-cases"></div>
      </div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in classicCasesList"
          :key="index"
        >
          <img :src="item.img" :alt="item.title" />
          <span>{{ item.title }}</span>
          <!-- <el-tooltip
            effect="dark"
            :content="item.desc"
            placement="top"
            v-if="item.desc.length > 24"
          > -->
          <span>{{ item.desc }}</span>
          <!-- </el-tooltip> -->
          <!-- <span v-else>{{ item.desc }}</span> -->
          <div class="detail">
            <span>查看详情</span>
            <img src="@/assets/imgs/home/arrow-left-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="about-us wrap">
      <div class="com-title">
        <span>关于我们</span>
        <span>/</span>
        <span>About us</span>
        <div class="anchor-point" id="about-us"></div>
      </div>
      <div class="content">
        <div class="left">
          <span>公司介绍</span>
          <span>PENGPAISHUZHI</span>
          <p>
            杭州澎湃数智科技有限公司成立于2019年，是一家智慧城市与智慧数字化整体软件与服务提供商。公司先后获得ISO9001质量认证体系、9项软著。从成立初期就致力于智慧城市、社区综合治理建设，参与并建设多个重点街道社区、智慧社区物联网平台以及综合治理解决方案，在该领域积累了相当丰富的行业经验，同时面向市场推出了自主知识产权的智慧信息化能力中台。创始团队拥有阿里、IBM、百度多年经历，核心研发来自阿里、大华、恒生电子、H3C、国外行业等公司。
            <br />
            杭州澎湃数智科技有限公司始终秉承“用专业和创新推动数智发展”使命，愿与行业精英一起建设“智慧、智能、
            智享的数字化生态圈”，拥抱智慧数字化服务！
          </p>
        </div>
        <div class="right"></div>
      </div>
      <div
        class="bottom"
        id="about"
        :style="{ 'background-position-y': positionY1 + 'px' }"
      ></div>
    </div>

    <!-- 我们的优势 -->
    <div class="our-advantages wrap">
      <div class="com-title white">
        <span>我们的优势</span>
        <span>/</span>
        <span>Our advantages</span>
        <div class="anchor-point" id="our-advantages"></div>
      </div>
      <div class="content">
        <div
          class="item animate__animated animate__slideInUp"
          v-for="(item, index) in ourAdvantagesList"
          :key="index"
        >
          <img :src="item.img" :alt="item.title" />
          <div class="desc">
            <span>{{ item.title }}</span>
            <span>{{ item.desc }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="cooperative-partner wrap">
      <div class="com-title">
        <span>合作伙伴</span>
        <span>/</span>
        <span>Cooperative partner</span>
        <div class="anchor-point" id="cooperative-partner"></div>
      </div>
      <div class="content">
        <vue-seamless-scroll
          class="seamless-scroll"
          :data="cooperativePartnerList"
          :class-option="optionHover"
        >
          <div class="item-box">
            <div
              class="item"
              v-for="(item, index) in cooperativePartnerList"
              :key="index"
              :class="(index + 2) % 3 === 0 ? 'tran' : ''"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- 底部 -->
    <div class="foot-tip">
      <div class="top wrap">
        <span>进一步了解针对您的最佳解决方案</span>
        <el-button
          type="primary"
          round
          @click="$refs.FormDialog.dialogFormVisible = true"
          >获取解决方案</el-button
        >
      </div>
      <div class="bottom wrap">
        <div class="address">
          <span>公司地址：</span>
          <span
            style="color: #76f2ff"
            @click="$refs.MapDialog.mapVisible = true"
            >浙江省杭州市拱墅区湖州街36-1号1303室</span
          >
        </div>
        <div class="email">
          <span>邮箱：</span>
          <span>info@papersz.com</span>
        </div>
        <img src="@/assets/imgs/home/foot-tip-img (2).jpg" alt="" />
      </div>
    </div>
    <div class="filing-no wrap">
      <a href="https://beian.miit.gov.cn/" target="blank"
        >浙ICP备2021030049号-2</a
      >
    </div>

    <FormDialog ref="FormDialog"></FormDialog>
    <MapDialog ref="MapDialog"></MapDialog>
    <div class="phone-number" v-show="phoneVisible">
      <div class="item">
        <img src="../../../assets/wechat-icon.png" alt="" />
        <span>微信公众号</span>
        <img src="../../../assets/wechat-code-icon.png" alt="" class="wechat" />
      </div>
      <div class="item">
        <img src="../../../assets/phone-icon2.png" alt="" />
        <span>联系我们</span>
        <div class="phone">0571-86808991</div>
      </div>
      <div class="item" @click="goAnchor('#banner')">
        <img src="../../../assets/backtop-icon.png" alt="" />
        <span>置顶</span>
      </div>
    </div>
  </div>
</template>

<script>
import FormDialog from "./components/FormDialog.vue";
import MapDialog from "./components/mapDialog.vue";
// import scroll from "vue-seamless-scroll";
import "animate.css";
var businessScope = [
  {
    title: "物联感知",
    desc: "物联网设备、无线感知协议融合",
    icon: require("../../../assets/imgs/home/business-scope-img (8).png"),
  },
  {
    title: "数字孪生",
    desc: "可视化建模、设计与开发",
    icon: require("../../../assets/imgs/home/business-scope-img (5).png"),
  },
  {
    title: "基础治理",
    desc: "深化三治融合、创新基层治理",
    icon: require("../../../assets/imgs/home/business-scope-img (3).png"),
  },
  {
    title: "业务中台",
    desc: "流程引擎、业务规则，提升协作效率",
    icon: require("../../../assets/imgs/home/business-scope-img (4).png"),
  },
  {
    title: "数字营销",
    desc: "提高用户活跃性，转化率，实现业绩快增",
    icon: require("../../../assets/imgs/home/business-scope-img (2).png"),
  },
  {
    title: "数据中台",
    desc: "赋能数据能力，以数据反哺业务",
    icon: require("../../../assets/imgs/home/business-scope-img (1).png"),
  },
  {
    title: "乡村品牌",
    desc: "挖掘乡村核心竞争优势，打造乡村品牌",
    icon: require("../../../assets/imgs/home/business-scope-img (7).png"),
  },
  {
    title: "融合通信",
    desc: "语音、网络等多渠道融合平台",
    icon: require("../../../assets/imgs/home/business-scope-img (6).png"),
  },
];
const classicCasesList = [
  {
    title: "智慧城市 高能高效",
    desc: "信息洞察各方环境，提高城市/政府/企业运营效率",
    img: require("../../../assets/imgs/home/classic-cases-img (3).png"),
  },
  {
    title: "智慧潮都 数字镇街",
    desc: "以智能化的“城市大脑”，实现利民/惠企/基层治理",
    img: require("../../../assets/imgs/home/classic-cases-img (1).png"),
  },
  {
    title: "数智村社 智慧网格",
    desc: "四社联动/服务零距离，打造安全高效新农村",
    img: require("../../../assets/imgs/home/classic-cases-img (2).png"),
  },
];
var ourAdvantages = [
  {
    title: "业务规划",
    desc: "团队具备多年行业经验，提供全方位咨询规划，内容涵盖营销、管理、创新业务、数据开发等",
    img: require("../../../assets/imgs/home/our-advantages-icon (3).png"),
  },
  {
    title: "行业整合",
    desc: "拥有整合多行业、跨场景、跨领域的业务能力，解决复杂业务痛点",
    img: require("../../../assets/imgs/home/our-advantages-icon (6).png"),
  },
  {
    title: "低代码平台",
    desc: "成熟、高效的快速开发平台，为高标准交付提供保障",
    img: require("../../../assets/imgs/home/our-advantages-icon (1).png"),
  },
  {
    title: "IOT中台",
    desc: "用灵活的物模型建立设备的数字孪生体，设备可通过多种通讯方式、插件式的数据协议接入云平台",
    img: require("../../../assets/imgs/home/our-advantages-icon (2).png"),
  },
  {
    title: "持续交付",
    desc: "以技术团队和技术资源优势，协助您完成软件系统的方案设计、开发测试保障持续的交付",
    img: require("../../../assets/imgs/home/our-advantages-icon (4).png"),
  },
  {
    title: "全程服务",
    desc: "根据您的业务和技术要求，制定技术规划、技术选型、技术方案、系统架构设计等",
    img: require("../../../assets/imgs/home/our-advantages-icon (5).png"),
  },
];
const cooperativePartnerList = [
  require("../../../assets/imgs/home/cooperative-partner-img (1).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (2).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (3).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (4).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (5).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (6).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (7).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (8).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (9).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (10).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (11).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (12).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (1).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (2).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (3).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (4).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (5).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (6).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (7).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (8).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (9).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (10).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (11).png"),
  require("../../../assets/imgs/home/cooperative-partner-img (12).png"),
];
export default {
  components: {
    FormDialog,
    MapDialog,
    vueSeamlessScroll:
      typeof window !== "undefined"
        ? require("vue-seamless-scroll")
        : undefined,
  },
  data() {
    return {
      businessScopeList: [],
      classicCasesList,
      ourAdvantagesList: [],
      cooperativePartnerList,
      positionY1: 0,
      Y1: 0,
      ratio: 0.2,
      timer: null,
      timer2: null,
      phoneVisible: false,
      getPostionOne: false,
      getPostionTwo: false,
    };
  },
  computed: {
    optionHover() {
      return {
        step: 0.6,
        limitMoveNum: 12,
        hoverStop: true,
        direction: 2,
        openWatch: true,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000,
      };
    },
  },
  watch: {
    getPostionOne: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        if (val) {
          this.transDelay(businessScope, this.businessScopeList, 8);
        }
      },
    },
    getPostionTwo: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        if (val) {
          this.transDelayTwo(ourAdvantages, this.ourAdvantagesList, 6);
        }
      },
    },
  },
  mounted() {
    console.log("版本标记：02.01");
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
      let bgTest = document.getElementById("about");
      this.Y1 = bgTest.offsetTop * this.ratio + 1300;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      scrollTop >= 300
        ? (this.phoneVisible = true)
        : (this.phoneVisible = false);
      if (scrollTop >= 2000) {
        this.getPostionOne = true;
      }
      if (scrollTop >= 4450) {
        this.getPostionTwo = true;
      }
      this.positionY1 = this.Y1 - scrollTop * this.ratio;
    },
    transDelay(arr, newArr, len) {
      this.timer = setInterval(() => {
        if (newArr.length === len) {
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        let item = arr.splice(0, 1)[0];
        newArr.push(item);
      }, 140);
    },
    transDelayTwo(arr, newArr, len) {
      this.timer2 = setInterval(() => {
        if (newArr.length === len) {
          clearInterval(this.timer2);
          this.timer2 = null;
          return;
        }
        let item = arr.splice(0, 1)[0];
        newArr.push(item);
      }, 140);
    },
    goAnchor(selector) {
      this.$nextTick(() => {
        document.querySelector(selector).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__slideInUp {
  --animate-duration: 0.8s;
}
.container {
  box-sizing: border-box;
  .wrap {
    width: 100%;
    height: auto;
    padding: 0 calc((100% - 1200px) / 2);
    background-color: #ffff;
    overflow: hidden;
  }
  .com-title {
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 36px;
    font-weight: 500;
    font-family: PingFang SC-Bold, PingFang SC;
    color: rgba(51, 51, 51, 1);
    margin: 60px auto 120px;
    position: relative;
    span:nth-child(2) {
      margin: 0 10px;
    }
    span:nth-child(3) {
      font-size: 32px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 300;
      color: rgba(51, 51, 51, 0.6);
    }
    .anchor-point {
      position: absolute;
      width: 0;
      height: 0;
      background: none;
      top: -100px;
    }
  }

  .business-scope {
    height: 896px;
    background-color: #f8f9fb;
    .content {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      row-gap: 90px;
      column-gap: 147px;
      .item {
        width: 179px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 120px;
          height: 120px;
        }
        span:nth-child(2) {
          font-size: 20px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 34px;
          margin: 10px 0 4px;
        }
        span:nth-child(3) {
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.6);
          line-height: 24px;
        }
      }
    }
  }

  .classic-cases {
    height: 747px;
    background-color: #ffffff;
    .content {
      display: flex;
      justify-content: space-between;
      .item {
        width: 386px;
        height: 357px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 10px 4px rgba(154, 154, 154, 0.15);
        transition: all ease-out 0.5s;
        cursor: pointer;
        img {
          width: 100%;
          height: 217px;
          display: block;
          object-fit: cover;
        }
        span:nth-child(2) {
          width: auto;
          height: 16px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 16px;
          margin: 28px 0 12px 32px;
        }
        span:nth-child(3) {
          width: auto;
          height: 20px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.6);
          line-height: 20px;
          margin: 0 32px 20px 32px;
        }
        .detail {
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          margin: 0 0 0 32px;
          span {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 500;
            color: #0075ff;
          }
          img {
            width: 18px;
            height: 18px;
            margin-left: 5px;
            transition: all 0.3s ease-out;
          }
        }
      }
      .item:hover {
        transition: all ease-out 0.5s;
        transform: translateY(-1%) scale(1.03);
        span:nth-child(2) {
          color: #347ff2;
        }
        .detail {
          img {
            margin-left: 16px;
            transition: all 0.3s ease-out;
          }
        }
      }
    }
  }

  .about-us {
    height: 913px;
    background-color: #f8f9fb;
    position: relative;
    .content {
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      background-color: #f8f9fb;

      .left {
        width: 875px;
        height: 396px;
        background: url("../../../assets/imgs/home/about-us-img\ \(1\).png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          width: auto;
          height: 20px;
          font-size: 20px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          margin: 38px 0 6px 79px;
        }
        span:nth-child(2) {
          width: auto;
          height: 16px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          line-height: 16px;
          margin: 0 0 15px 79px;
        }
        p {
          width: 520px;
          height: auto;
          font-size: 15px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 26px;
          margin-left: 79px;
        }
      }
      .right {
        width: 543px;
        height: 312px;
        background: url("../../../assets/imgs/home/about-us-img\ \(2\).png");
        background-size: 100% 100%;
        position: absolute;
        top: 42px;
        right: 0;
      }
    }
    .bottom {
      background-color: #f8f9fb;
      position: absolute;
      left: 0;
      top: 292px;
      z-index: 1;
      width: 100%;
      height: 641px;
      background: url("../../../assets/imgs/home/about-us-img\ \(3\).png");
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: 100% auto;
    }
  }

  .our-advantages {
    height: 782px;
    background: url("../../../assets/imgs/home/our-advantages-img.png");
    background-size: 100% 100%;
    .white {
      span {
        color: #ffff;
      }
    }
    .content {
      width: 100%;
      height: auto;
      display: flex;
      column-gap: 20px;
      row-gap: 20px;
      flex-wrap: wrap;
      .item {
        width: 385px;
        height: 166px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 40px;
        img {
          width: 60px;
          height: 60px;
        }
        .desc {
          width: 224px;
          height: auto;
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            width: auto;
            height: 20px;
            font-size: 20px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 20px;
            margin-bottom: 16px;
          }
          span:nth-child(2) {
            width: 224px;
            height: auto;
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.6);
            line-height: 22px;
          }
        }
      }
    }
  }
  .cooperative-partner {
    height: 770px;
    background-color: #f8f9fb;
    .content {
      width: 1200px;
      height: 372px;
      overflow: hidden;
      .seamless-scroll {
        .item-box {
          width: 1212px;
          height: 372px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          .item {
            width: 291px;
            height: 112px;
            background: #ffffff;
            padding: 10px 15px;
            margin: 0 12px 12px 0;
            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: contain;
            }
          }
          .tran {
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .foot-tip {
    height: 460px;
    .top {
      height: 190px;
      background: url("../../../assets/imgs/home/foot-tip-img (1).png");
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: auto;
        height: 36px;
        font-size: 36px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 36px;
      }
      .el-button {
        width: 200px;
        height: 60px;
        background: linear-gradient(
          180deg,
          #fffffd 0%,
          rgba(223, 240, 254, 0.8) 100%
        );
        border-radius: 100px;
        border: none;
        font-size: 20px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 500;
        color: #347ff2;
      }
      .el-button:active {
        transform: scale(0.95);
      }
    }
    .bottom {
      height: 276px;
      background: #20355c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .address {
        cursor: pointer;
      }
      .address,
      .email {
        display: flex;
        flex-direction: column;
        span:nth-child(1) {
          width: auto;
          height: 20px;
          font-size: 20px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          margin-bottom: 24px;
        }
        span:nth-child(2) {
          width: auto;
          height: 24px;
          font-size: 24px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 24px;
        }
      }
      img {
        width: 140px;
        height: 140px;
      }
    }
  }
  .filing-no {
    height: 52px;
    background: #182a4d;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    a {
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      color: #b5b5b5;
    }
  }
  .phone-number {
    width: 87px;
    height: 330px;
    position: fixed;
    bottom: 20%;
    right: 24px;
    z-index: 1200;
    background: rgba(32, 53, 92, 0.6);
    border-radius: 4px;
    .item {
      width: 100%;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        width: auto;
        height: auto;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .wechat {
        position: absolute;
        top: 0;
        left: -213px;
        transform: translateY(-55px);
        width: 196px;
        height: 218px;
        display: none;
      }
      .phone {
        position: absolute;
        top: 0;
        left: -237px;
        width: 237px;
        height: 108px;
        background: rgba(32, 53, 92, 0.6);
        text-align: center;
        line-height: 108px;
        align-items: center;
        font-size: 26px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: none;
      }
    }
    .item:nth-child(1) {
      border-radius: 4px 4px 0 0;
    }
    .item:nth-child(2) {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
    .item:nth-child(3) {
      border-radius: 0 0 4px 4px;
    }
    .item:hover {
      background: #5893a4;
    }
    .item:nth-child(1):hover {
      .wechat {
        display: block;
      }
    }
    .item:nth-child(2):hover {
      .phone {
        display: block;
      }
    }
  }
}
</style>
