import axios from "axios";
// import { Message } from "ant-design-vue";

// 请求超时时间
axios.defaults.timeout = 30000;
// axios.defaults.headers.put["Content-Type"] = "application/json";
// axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.post["accept"] = "*/*";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

// 请求拦截器
// axios.interceptors.request.use(
//   config => {
//     return config;
//   },
//   error => {
//     return Promise.error(error);
//   }
// );

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const { code } = response.data;
      if (code === 0) {
        return Promise.resolve(response.data);
      } else {
        //todo 隐藏报错
        // Message.error(response.data.msg);
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  error => {
    return Promise.reject(error.response);
  }
);

export const request = axios

// /**
//  * get方法，对应get请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function get(url, params) {
//   return axios({
//     url,
//     method: "get",
//     params,
//   });
// }
// /**
//  * post方法，对应post请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} data [请求时携带的参数]
//  */
// export function post(url, params, data) {
//   return axios({
//     url,
//     method: "post",
//     params,
//     data,
//   });
// }

// export function put(url, params) {
//   return axios({
//     url,
//     method: "put",
//     params,
//   });
// }
// export function del(url, params) {
//   return axios({
//     url,
//     method: "delete",
//     params,
//   });
// }
