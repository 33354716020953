// import { get, post, put, del } from "./request";
import { request } from "./request";

// export const reservationPage = params => get("/reservation/page", params);

// export const reservationAdd = data => post("/reservation", null, data);

export const reservationAdd = data =>
  request({
    url: "/reservation",
    method: "post",
    data,
  });
