<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    destroy-on-close
    lock-scroll
    @closed="dialogClosed"
  >
    <div class="dialog-body">
      <span class="title">联系我们</span>
      <span class="desc">提交您的需求，我们将尽快与您联系！</span>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="name">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="*您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            v-model="form.phone"
            autocomplete="off"
            placeholder="*您的手机号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input
            v-model="form.company"
            autocomplete="off"
            placeholder="*您的公司名称"
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="desc">
          <el-input
            type="textarea"
            placeholder="*需求描述"
            v-model="form.desc"
            maxlength="200"
            show-word-limit
            :autosize="{ minRows: 5, maxRows: 5 }"
            resize="none"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="handleSubmit()">立即提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reservationAdd } from "@/common/api";

export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        phone: "",
        company: "",
        desc: "",
      },
      // formLabelWidth: "120px",
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, message: "请输入姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        desc: [{ required: true, message: "请输入需求描述", trigger: "blur" }],
      },
    };
  },
  methods: {
    dialogClosed() {
      this.form = {};
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          reservationAdd({
            reservationUserName: this.form.name,
            reservationUserPhone: this.form.phone,
            requirementsDescribe: this.form.desc,
          })
            .then(res => {
              this.$message.success("提交成功，专业顾问将于24小时内与您联系");
              this.dialogFormVisible = false;
            })
            .catch(() => this.$message.error("提交失败"));
        } else {
          this.$message.warning("请您填写完整，以便顾问了解您的需求");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-dialog {
  transform: scale(0.75);
  width: 873px;
  height: 742px;
  background: #ffffff;
  border-radius: 52px 0px 52px 0px;
  .el-dialog__headerbtn {
    font-size: 25px;
    top: 25px;
    right: 25px;
  }
  .dialog-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 144px;
      height: 54px;
      font-size: 36px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #192738;
      line-height: 42px;
    }
    .desc {
      width: 340px;
      height: 30px;
      font-size: 20px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(25, 39, 56, 0.5);
      line-height: 23px;
      margin-bottom: 32px;
    }

    .el-input__inner {
      width: 750px;
      height: 60px;
    }
    .el-input__count {
      bottom: -40px;

      color: rgba(25, 39, 56, 0.2);
    }
    .el-textarea__inner {
      width: 750px;
      min-height: 140px;
    }
    .el-button {
      margin-top: 55px;
      width: 757px;
      height: 72px;
      background: linear-gradient(90deg, #34aef2 0%, #347ff2 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      > span {
        width: 96px;
        height: 36px;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        letter-spacing: 10px;
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .el-input__inner,
  .el-textarea__inner,
  .el-input__count {
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  }
}
</style>
