<template>
  <div id="top-container">
    <!-- banner -->
    <div class="banner-wrap" id="banner">
      <div class="banner">
        <div class="banner-left">
          <p>顶尖智慧数字服务提供商</p>
          <p>深耕智慧场景 数据共融共享<br />推动数字发展 携手共赢未来</p>
        </div>
        <div class="banner-right">
          <img src="@/assets/people.png" alt="" class="people" />
          <img src="@/assets/banner.gif" alt="" class="gif" />
        </div>
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="solution-plan-wrap">
      <div class="solution-title">
        <span>解决方案</span> / <span>Solution</span>
        <div class="anchor-point" id="solution-plan"></div>
      </div>

      <div class="involved-area">
        <div class="area-item">
          <img src="@/assets/Frame 1@2x.png" alt="" class="area-icon" />
          <span class="title">数字服务</span>
          <span class="desc">实现乡村数字化治理服务</span>
          <div class="area-arrow">
            <img src="@/assets/area-arrow.png" alt="" />
          </div>
        </div>
        <div class="area-item">
          <img src="@/assets/Frame 4@2x.png" alt="" class="area-icon" />
          <span class="title">数字产业</span>
          <span class="desc">构建企业集群/强化企业链条</span>
          <div class="area-arrow">
            <img src="@/assets/area-arrow.png" alt="" />
          </div>
        </div>
        <div class="area-item">
          <img src="@/assets/Frame 2@2x.png" alt="" class="area-icon" />
          <span class="title">数字教育</span>
          <span class="desc">实现教育数字化管理升级</span>
          <div class="area-arrow">
            <img src="@/assets/area-arrow.png" alt="" />
          </div>
        </div>
        <div class="area-item">
          <img src="@/assets/Frame 3@2x.png" alt="" class="area-icon" />
          <span class="title">数字金融</span>
          <span class="desc">智能化的“智慧金融”新时代</span>
          <div class="area-arrow">
            <img src="@/assets/area-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div
        class="solution-plan"
        style="margin: 320px auto 100px"
        @mouseenter="showPlane1 = true"
        @mouseleave="showPlane1 = false"
      >
        <div class="left-bg-img" style="top: 157px; left: -211px"></div>
        <div class="plan-left">
          <div class="left-title">
            <img src="@/assets/title-icon.png" alt="" />
            智慧城市解决方案
          </div>
          <p>
            致力于创新服务，围绕“新型智慧城市”的目标，通过体系规划、信息主导、改革创新，推进新一代信息技术与城市现代化深度融合，聚合数字孪生、可视化、大数据、人工智能与物联网等多种技术手段提供移动互联网、城市物联网等解决方案和服务，打造数字网格化管理服务与决策功能的综合信息体系。
          </p>
          <paper-plane-vue v-if="showPlane1"></paper-plane-vue>
        </div>
        <div class="plan-right">
          <img src="@/assets/bg (1).png" alt="" />
          <div class="bg"></div>
        </div>
      </div>
      <div class="divider"></div>
      <div
        class="solution-plan"
        style="margin-bottom: 100px"
        @mouseenter="showPlane2 = true"
        @mouseleave="showPlane2 = false"
      >
        <div class="right-bg-img" style="top: 246px; right: -210px"></div>
        <div class="plan-right">
          <img src="@/assets/bg (2).png" alt="" />
          <div class="bg" style="left: -20px"></div>
        </div>
        <div class="plan-left">
          <div class="left-title">
            <img src="@/assets/title-icon.png" alt="" />
            智慧乡村解决方案
          </div>
          <p>
            智慧乡村旨在助力乡村产业振兴/人才振兴/文化振兴/生态振兴/组织振兴推动农业全面升级、农村全面进步、农民全面发展。打造专业的基层治理及服务应用矩阵，服务于政务办公及政民互动等场景。以云计算、物联网、大数据等数字技术，驱动农业农村发展质量、效率和动力变革，打通三农领域的信息壁垒，实现管理运作规范化、自动化、程序化、简便化，对内实现村级事务管理、为民生服务智能化对外实现宣传媒介、引导应用智慧化，建设新型农业经济。
          </p>
          <paper-plane-vue v-if="showPlane2"></paper-plane-vue>
        </div>
      </div>
      <div class="divider"></div>
      <div
        class="solution-plan"
        @mouseenter="showPlane3 = true"
        @mouseleave="showPlane3 = false"
      >
        <div class="left-bg-img" style="top: 234px; left: -211px"></div>
        <div class="plan-left">
          <div class="left-title">
            <img src="@/assets/title-icon.png" alt="" />
            智慧物联网解决方案
          </div>
          <p>
            通过物联网、云计算、地理空间等新一代信息技术以及维基、社交网络、Fab
            Lab、Living
            Lab、综合集成法、网动全媒体融合通信终端等工具和方法的应用，实现全面透彻的感知、宽带泛在的互联、智能融合的应用。帮助客户对设备的图像、音频、位置、环境感知等数据，进行存储、分析与智慧化处理，为客户打造一站式数字化应用服务。
          </p>
          <paper-plane-vue v-if="showPlane3"></paper-plane-vue>
        </div>
        <div class="plan-right">
          <img src="@/assets/bg (3).png" alt="" />
          <div class="bg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaperPlaneVue from "@/views/component/PaperPlane.vue";
export default {
  components: {
    PaperPlaneVue,
  },
  data() {
    return {
      showPlane1: false,
      showPlane2: false,
      showPlane3: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#top-container {
  width: 100%;
  box-sizing: border-box;
  .banner-wrap {
    width: 100%;
    height: 922px;
    background: url("../../../assets/banner-bg-img.png");
    background-size: cover;
    overflow: hidden;
    .banner {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      position: relative;
      .banner-left {
        width: 995px;
        height: 645px;
        position: absolute;
        left: -170px;
        top: 130px;
        background: url("../../../assets/banner-title-bg.png");
        background-size: 100% 100%;
        > p {
          &:nth-child(1) {
            width: auto;
            height: 75px;
            font-size: 50px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            line-height: 75px;
            margin: 209px 0 40px 247px;
          }
          &:nth-child(2) {
            width: auto;
            height: 88px;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 44px;
            margin: 0 0 0 247px;
          }
        }
      }
      .banner-right {
        position: absolute;
        width: 565px;
        height: 579px;
        top: 130px;
        right: -70px;
        > img.gif {
          width: 100%;
          height: 100%;
        }
        > img.people {
          position: absolute;
          height: 150px;
          bottom: 30px;
          left: 84px;
        }
      }
    }
  }

  .solution-plan-wrap {
    width: 100%;
    height: auto;
    .solution-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 236px;
      font-size: 36px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      .anchor-point {
        position: absolute;
        width: 0;
        height: 0;
        background: none;
        top: -100px;
      }
      > span {
        font-size: 32px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 300;
        color: rgba(51, 51, 51, 0.6);
        line-height: 38px;
      }
      span:first-child {
        width: 144px;
        height: 50px;
        font-size: 36px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 42px;
        // background: linear-gradient(90deg, #387bf0 0%, #6be0cc 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }
    .involved-area {
      margin: 0 auto;
      width: 1200px;
      height: auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      .area-item {
        width: 276px;
        height: 207px;
        background: #ffffff;
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        transition: all ease-out 0.5s;
        cursor: pointer;
        box-shadow: 0px 4px 40px 0px rgba(150, 205, 255, 0.25);
        margin-top: -121px;
        .title {
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          // font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 19px;
        }
        .desc {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.6);
          line-height: 16px;
        }
        img.area-icon {
          width: 88px;
          height: 88px;
        }
        .area-arrow {
          width: 26px;
          height: 26px;
          position: relative;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: -26px;
            opacity: 0;
          }
        }
        &:hover {
          transition: all ease-out 0.5s;
          transform: translateY(-5%) scale(1.03);
          .title {
            color: #347ff2;
          }
          .area-arrow {
            > img {
              transition: all ease 1s;
              transform: translateX(26px);
              opacity: 1;
            }
          }
        }
      }
    }
    .solution-plan {
      width: 1200px;
      height: 310px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      transition: all ease 0.5s;
      margin: 100px auto 190px;
      position: relative;
      .left-bg-img {
        width: 130px;
        height: 228px;
        background: url("../../../assets/letf-bg-img.png");
        background-size: 100% 100%;
        position: absolute;
      }
      .right-bg-img {
        width: 130px;
        height: 228px;
        background: url("../../../assets/right-bg-img.png");
        background-size: 100% 100%;
        position: absolute;
      }
      .plan-left {
        .left-title {
          transition: all ease 0.5s;
          font-size: 28px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 28px;
          > img {
            width: 34px;
            height: 34px;
            vertical-align: middle;
            margin-top: -5px;
            margin-right: -8px;
          }
        }
        > p {
          transition: all ease 0.5s;
          margin-top: 40px;
          width: 601px;
          height: 155px;
          font-size: 18px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.6);
          line-height: 31px;
        }
      }
      .plan-right {
        transition: all ease 0.5s;
        position: relative;
        > img {
          width: 513px;
          height: 290px;
          position: relative;
          z-index: 2;
        }
        > .bg {
          width: 440px;
          height: 248px;
          background: linear-gradient(90deg, #387bf0 0%, #6be0cc 100%);
          position: absolute;
          right: -20px;
          bottom: 0px;
        }
      }

      &:hover {
        .plan-right {
          transform: scale(0.95);
        }
        .plan-left {
          .left-title {
            transform: translateY(10px);
          }
          > p {
            transform: translateY(-10px);
          }
        }
      }
    }
    .divider {
      margin: 0 auto;
      width: 1193px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(186, 186, 186, 0.2);
      transform: scaleY(0.5);
    }
  }
}
</style>
