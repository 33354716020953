import Vue from "vue";
import App from "./App.vue";
import router, { createRouter } from "./router";
import store, { createStore } from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/scss/style.scss";

Vue.use(ElementUI);
Vue.config.productionTip = false;

export function createApp() {
  // 创建 router, store 实例
  const router = createRouter();
  const store = createStore();
  const app = new Vue({
    router,
    store,
    render: h => h(App),
  });

  return { app, router, store };
}
