<template>
  <div class="container">
    <el-dialog
      title="浙江省杭州市拱墅区湖州街36-1号1303室"
      :visible.sync="mapVisible"
    >
      <div id="map"></div>
    </el-dialog>
  </div>
</template>

<script>
// import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      mapVisible: false,
      map: null,
      AMapLoader: null,
    };
  },
  watch: {
    mapVisible: {
      deep: true,
      handler: function (val, oldVal) {
        if (val) {
          this.$nextTick(() => {
            this.initMap();
          });
        }
      },
    },
  },
  mounted() {
    if (typeof window !== "undefined") {
      window._AMapSecurityConfig = {
        securityJsCode: "e76ea5b74eab3779347e14cb4d0ed44f",
      };
      this.AMapLoader = require("@amap/amap-jsapi-loader");
    }
  },
  methods: {
    initMap() {
      this.AMapLoader.load({
        key: "24ffb56cdc414e4fd6d7b5a9b2bbe3af",
        version: "2.0",
        plugins: [],
      })
        .then(AMap => {
          this.map = new AMap.Map("map", {
            viewMode: "2D",
            zoom: 17,
            center: [120.159282, 30.325236],
            resizeEnable: true,
          });
          var marker = new AMap.Marker({
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            position: [120.159282, 30.325236],
          });
          this.map.add(marker);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  #map {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
  }
}
::v-deep .el-dialog {
  border-radius: 8px;
  width: 800px;
  height: 420px;
}
::v-deep .el-dialog__body {
  width: 100%;
  height: 100%;
  padding: 0;
}
</style>
<style>
/* 隐藏高德logo  */
.amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
  display: none !important;
}
</style>
