<template>
  <div class="container">
    <!-- 固定导航 -->
    <div class="header-wrap" :class="isScrollTop ? '' : 'is-no-top'">
      <div class="company">
        <img src="@/assets/logo.png" alt="" class="logo" />
        <div class="name">
          <span>澎湃数智</span>
          <span>Paper Digital</span>
        </div>
      </div>
      <div class="header">
        <ul class="nav">
          <li class="item" @click="goAnchor('#banner')"><a>首页</a></li>
          <li class="item" @click="goAnchor('#solution-plan')">
            <a>解决方案</a>
          </li>
          <li class="item" @click="goAnchor('#business-scope')">
            <a>业务范畴</a>
          </li>
          <li class="item" @click="goAnchor('#classic-cases')">
            <a>经典案例</a>
          </li>
          <li class="item" @click="goAnchor('#about-us')"><a>关于我们</a></li>
          <li class="item" @click="goAnchor('#our-advantages')">
            <a>我们的优势</a>
          </li>
          <li class="item" @click="goAnchor('#cooperative-partner')">
            <a>合作伙伴</a>
          </li>
        </ul>
      </div>
    </div>
    <top></top>
    <Bootom></Bootom>
  </div>
</template>

<script>
import Top from "@/views/overView/home/top.vue";
import Bootom from "@/views/overView/home/bottom.vue";

export default {
  name: "Home",
  components: {
    Top,
    Bootom,
  },
  data() {
    return {
      isScrollTop: true,
    };
  },
  mounted() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.changeBg);
    }
  },
  methods: {
    changeBg() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        this.isScrollTop = false;
      } else {
        this.isScrollTop = true;
      }
    },
    goAnchor(selector) {
      this.$nextTick(() => {
        document.querySelector(selector).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .header-wrap {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.2);
    .company {
      position: absolute;
      width: auto;
      height: 64px;
      top: 10px;
      left: 7.4%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s ease-out;
      .logo {
        width: 64px;
        height: 64px;
        margin-right: 10px;
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span:nth-child(1) {
          width: auto;
          height: 24px;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          letter-spacing: 6px;
        }
        span:nth-child(2) {
          width: auto;
          height: 15px;
          font-size: 13px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 15px;
        }
      }
    }

    .header {
      margin: 0 auto;
      width: 1200px;
      height: 80px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ul.nav {
        display: flex;
        li.item {
          > a {
            height: 80px;
            display: block;
            box-sizing: border-box;
            padding: 29px 22px;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 16px;
            color: #ffffff;
            &:hover {
              color: #ffffff !important;
              background: #347ff2;
            }
          }
        }
      }
    }
  }
  .is-no-top {
    box-shadow: 0 4px 10px 0px rgba(154, 154, 154, 0.1);
    background: #ffffff;
    .company {
      transform: scale(0.9);
      transition: all 0.3s ease-out;
      .name span {
        color: #347ff2 !important;
      }
    }
    .header > ul > li {
      a {
        color: #333333 !important;
      }
    }
  }
}
</style>
